import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"

export default class WorkPage extends React.Component {
  constructor(props){
    super(props);
    this.state = { filteredCollection: [], currentFilter: 'All'};

    // this.filterCollection = this.filterCollection.bind(this);
  }

  filterCollection(tag) {
    let filteredCollection = [];

    const projects = this.props.data.sortedPosts.entries.filter( post => post.__typename === 'ContentfulProject' );

    projects.forEach((project) => {
      if (project.tags) {
        project.tags.forEach((item) => {
          if (item.title === tag) {
            filteredCollection.push(project)
          }
        })
      }
    })

    this.setState({filteredCollection: filteredCollection, currentFilter: tag})
  }

  linkUrl(post) {
    const typeSlugs = {
      'ContentfulProject': '/work/',
      'ContentfulPost': '/news/'
    }
    if( typeSlugs[post.__typename] !== undefined ){
      return typeSlugs[post.__typename] + post.slug + '/'
    }
    return '/' + post.slug + '/' /// fallback should never happen
  }

  clearFilter() {
    this.setState({filteredCollection: [], currentFilter: 'All'})
  }

  render() {
    let projects = this.props.data.sortedPosts.entries
    const { title, bodyBgColor, bodyTextColor, bodyTitleColor, seo } = this.props.data.contentfulStaticPage
    let tags = [];

    const contentfulProjects = projects.filter( post => post.__typename === 'ContentfulProject' );
    contentfulProjects.forEach((project, index) => {
      if (project.tags) {
        project.tags.forEach((tag, index) => {
          tags.push(tag.title)
        });
      }
    });

    tags = [...new Set(tags)]

    const renderTag = (itemNode) => {
      if( itemNode.__typename === 'ContentfulProject') {
        let tags = itemNode?.tags ?? []
        tags = tags.map( tag => tag.title)
    
        return (
          <span>
            {itemNode.client}
            { tags.length > 0 &&
              <> - {tags.join(', ')}</>
            }
          </span>
        )
      }

      return (
        <span>
          {itemNode.createdAt}
        </span>
      )
    }

    projects = this.state.currentFilter === 'All' ? projects : this.state.filteredCollection

    return (
      <Layout
        bodyBgColor={bodyBgColor}
        bodyTextColor={bodyTextColor}
        bodyTitleColor={bodyTitleColor}
        pageTitle={seo && seo.seoTitle ? seo.seoTitle : title}
        pageDescription={seo && seo.description ? seo.description : null}
        pageImage={seo && seo.image ? seo.image.file.url : null}
        pageArticle={seo && seo.article ? seo.article : false}
        pathname={'/work'}
      >
        <div className="container mt-32 md:mt-42">
        <h1>{title}</h1>

          {
            tags &&
            <div className="mt-12 mb-8">
              <button className="button-bold-preserved" onClick={this.clearFilter.bind(this)}>
                <span title="All" className={`mr-8 ${this.state.currentFilter === 'All' ? 'selected' : ''}`}>All</span>
              </button>
              {tags.map((tag, index) => (
                <button key={index} className="button-bold-preserved" onClick={this.filterCollection.bind(this, tag)}>
                  <span title={tag} className={`${index < tags.length - 1 ? 'mr-8' : ''} ${this.state.currentFilter === tag ? 'selected' : ''}`}>{tag}</span>
                </button>
              ))}
            </div>
          }

          {
            projects.length > 0 &&
            <div className="mt-0 md:mt-0 mb-10 flex flex-wrap -mx-12">
              {projects.map((project, index) => (
                <div key={index} className="w-full md:w-1/2 px-12 mb-20 md:mb-26">
                  <Link to={this.linkUrl(project)} className="group-hover">
                    {project.featuredImage && <Img fluid={project.featuredImage.fluid} className="mb-6" />}
                    { renderTag(project) }
                    <h3 className="mt-2 underline group-hover:textDecoration-none">{project.title}</h3>
                  </Link>
                </div>
              ))}
            </div>
          }
        </div>
      </Layout>
    )
  }
}

export const query = graphql`
  query workQuery {
    contentfulStaticPage(slug: {eq: "work"}) {
      title
      bodyBgColor
      bodyTextColor
      bodyTitleColor
      seo {
        ... on Node {
            ... on ContentfulSeo {
            article
            seoTitle
            description
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
    sortedPosts: contentfulSortContent(slug: {eq: "work"}) {
      entries {
        __typename
        ... on Node {
          ... on ContentfulProject {
            createdAt(formatString: "MMMM D, Y")
            featuredImage {
              fluid(maxWidth: 1020, quality: 70) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            intro {
              childMarkdownRemark {
                excerpt(format: PLAIN, pruneLength: 250, truncate: true)
              }
            }
            slug
            title
            client
            tags {
              title
            }
          }
          ... on ContentfulPost {
            createdAt(formatString: "MMMM d, Y")
            featuredImage {
              fluid(maxWidth: 1020, quality: 70) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            intro {
              childMarkdownRemark {
                excerpt(format: PLAIN, pruneLength: 250, truncate: true)
              }
            }
            slug
            title
          }
        }
      }
    }
  }
`
